require('./bootstrap');

require('./addCompanyItem');
require('./autonumeric');
require('./colorpicker');
require('./companyCreation');
require('./datatable');
require('./dialog');
require('./dropzone');
require('./flatpickr');
require('./forms');
require('./globalSearch');
require('./sectionItems');
require('./select2');
require('./sortable');
require('./tooltip');
require('./popover');
require('./utilities');

require('./components/company-item-card');
require('./components/checklist');
require('./components/editable-card-functions');
require('./components/editable-card');
require('./components/task-card');
require('./components/context-selector');
require('./components/office-document-template');
require('./components/task-comments');
require('./components/task-description');

window.renderProjectStateText = (projectState) => {
    switch (projectState) {
        case 'request':
            return 'Anfrage';
        case 'first-contact-made':
            return 'Erstkontakt erfolgt';
        case 'offer-transmitted':
            return 'Angebot übermittelt';
        case 'follow-up-phone-call':
            return 'Nachtelefonieren';
        case 'standby-position':
            return 'Wartestellung';
        case 'gained':
            return 'gewonnen';
        case 'lost':
            return 'verloren';
        case 'done':
            return 'erledigt';
        default:
            return '';
    }
};

window.renderProjectStates = (projectState, rowData) => {

    let isDone = rowData['is_done'];
    let completionDate = rowData['completion_date'];
    let barWidth = rowData['progress_bar_width'];
    let barCssClass = rowData['progress_bar_css_class'];
    let textCssClass = barCssClass;
    if (textCssClass == 'bg-success') {
        textCssClass = '';
    }

    if (barWidth > 100) {
        barWidth = 100;
    }

    let output = '<div class="d-flex justify-content-between align-items-center boxanize-project-states">';

    let aClass = 'bg-gray-200';
    let bClass = 'bg-gray-200';
    let cClass = 'bg-gray-200';
    let dClass = 'bg-gray-200';
    let eClass = 'bg-gray-200';

    if (projectState == 'lost') {
        if (isDone) {
            output += '<div class="boxanize-project-state-lost-done"' + '"></div></div>';
        } else {
            output += '<div class="boxanize-project-state-lost"' + '"></div></div>';
        }
    } else if (projectState == 'gained') {
        if (isDone) {
            output += '<div class="boxanize-project-state-gained-done"' + '"></div></div>';
        } else {
            output += '<div class="boxanize-project-state-gained"' + '"></div></div>';
        }
    } else {
        switch (projectState) {
            case 'request':
                aClass = 'bg-primary';
                break;
            case 'first-contact-made':
                aClass = 'bg-primary';
                bClass = 'bg-primary';
                break;
            case 'offer-transmitted':
                aClass = 'bg-primary';
                bClass = 'bg-primary';
                cClass = 'bg-primary';
                break;
            case 'follow-up-phone-call':
                aClass = 'bg-primary';
                bClass = 'bg-primary';
                cClass = 'bg-primary';
                dClass = 'bg-primary';
                break;
            case 'standby-position':
                aClass = 'bg-primary';
                bClass = 'bg-primary';
                cClass = 'bg-primary';
                dClass = 'bg-primary';
                eClass = 'bg-primary';
                break;
            case 'done':
                aClass = 'bg-gray-200';
                bClass = '';
                cClass = '';
                dClass = '';
                eClass = '';
                break;
        }

        output += '<div class="boxanize-project-state ' + aClass + '"></div>';
        output += '<div class="boxanize-project-state ' + bClass + '"></div>';
        output += '<div class="boxanize-project-state ' + cClass + '"></div>';
        output += '<div class="boxanize-project-state ' + dClass + '"></div>';
        output += '<div class="boxanize-project-state ' + eClass + '"></div>';
        output += '</div>';
    }

    if (! isDone && completionDate && projectState !== 'lost') {
        output += '<div class="project-table-progress-container clearfix">';
        output += '<div class="project-table-progress-bar"><div class="progress-bar-display ' + barCssClass + '" style="width:' + barWidth +'%" ></div></div>';
        output += '<div class="project-table-progress-text ' + textCssClass + '">' + completionDate + '</div>';
        output += '</div>';
    }


    return output;
}

(function($) {
    $.fn.extend({
        triggerAll: function (events, params) {
            var el = this, i, evts = events.split(' ');
            for (i = 0; i < evts.length; i += 1) {
                el.trigger(evts[i], params);
            }
            return el;
        }
    });
})(jQuery);

$(function () {
    $('.filter-user-settings').on('change', function() {
        let $save_url = window.CRM.save_url;
        let $id = $(this).attr('id');
        let $filterGroup = $(this).data('user-setting-filter-group');

        if (!$save_url) {
            return;
        }

        axios.put($save_url,  {
            id: $id,
            type: 'filter',
            value: $(this).val(),
            group: $filterGroup,
        }).then(function() {
            // do nothing for now...
        });
    });

    $('.checkbox-user-settings').on('change', function() {
        let $save_url = window.CRM.save_url;
        let $id = $(this).attr('id');

        if (!$save_url) {
            return;
        }

        axios.put($save_url,  {
            id: $id,
            type: 'checkbox',
            checked: $(this).is(':checked')
        }).then(function() {
            // do nothing for now...
        });
    });

    $('.js-mobile-collapsible').on('click', function() {
        let $save_url = window.CRM.save_url;
        let $id = $(this).attr('id');

        if (!$save_url) {
            return;
        }

        axios.put($save_url,  {
            id: $id,
            type: 'checkbox',
            checked: ! $(this).hasClass('js-collapsible-selected'),
        }).then(function() {
            // do nothing for now...
        });
    });

    // automatically save user settings for collapsible items
    $('.collapse').on('shown.bs.collapse hidden.bs.collapse', function(e) {

        let $save_url = window.CRM.save_url;
        let $id = $(this).attr('id');

        if (!$save_url) {
            return;
        }

        axios.put($save_url,  {
            id: $id,
            type: 'open_tabs',
            event: e.type
        })
            .then(function() {
                // do nothing for now.
            });
    })

    $('.active-user-settings').on('click', function() {

        let $save_url = window.CRM.save_url;
        if (!$save_url) {
            return;
        }

        let $id = $(this).attr('id');
        let $group = $(this).data('group');

        if (!$group) {
            return;
        }

        axios.put($save_url,  {
            id: $id,
            type: 'active',
            group: $group,
            active: true
        })
            .then(function() {
                // do nothing for now.
            });
    });


    try {
        if ($('#taskUsersContainer').length > 0) {
            window.psTaskUsers = new PerfectScrollbar('#taskUsersContainer', {
                wheelSpeed: 2,
                wheelPropagation: false,
                minScrollbarLength: 20
            });
        }
    } catch (ex) {
        console.log(ex);
    }

    try {
        if($('#taskCategoriesContainer').length > 0) {
            window.psTaskCategories = new PerfectScrollbar('#taskCategoriesContainer', {
                wheelSpeed: 2,
                wheelPropagation: false,
                minScrollbarLength: 20
            });
        }
    } catch (ex) {
        console.log(ex);
    }

    try {
        if ($('#taskCompaniesContainer').length > 0) {
            window.psTaskCompanies = new PerfectScrollbar('#taskCompaniesContainer', {
                wheelSpeed: 2,
                wheelPropagation: false,
                minScrollbarLength: 20
            });
        }
    } catch (ex) {
        console.log(ex);
    }
})
