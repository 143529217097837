window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.jQuery = window.$ = require('jquery');
require('jquery-debounce-throttle');

window.DataTable = require('datatables.net-bs5')
require('datatables.net-responsive');
require('datatables.net-responsive-bs5');
require('datatables.net-rowreorder-bs5');
require('datatables.net-buttons-bs5');
require('datatables.net-buttons/js/buttons.html5.min');

window.JSZip = require("jszip");
$.fn.dataTable.Buttons.jszip(JSZip);

window.AutoNumeric = require('autonumeric');

window.popper = require('popper.js');
window.bootstrap = require('bootstrap');
require('bootstrap-colorpicker');

require('jquery-toast-plugin');

require('select2/dist/js/select2.full.min');
require('select2/dist/js/i18n/de');

window.Sortable = require('sortablejs/Sortable.min');

window.Dropzone = require('dropzone');
require('flatpickr');

import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;

import Bloodhound from 'typeahead.js';
window.Bloodhound = Bloodhound;
require('typeahead.js/dist/typeahead.jquery.min');

import Tagify from '@yaireo/tagify';
window.Tagify = Tagify;
