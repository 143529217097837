window.initToolTip = (container) => {
    $(container + ' [data-bs-toggle="tooltip"]').each(function (index, element) {
        new bootstrap.Tooltip(element)
    });
}

$(function () {
    initToolTip('body');
});

$(document).on('af.modal.shown', function () {
    initToolTip('#ajaxContentModal');
    initToolTip('#ajaxContentModalNoPadding');
})
